.stats-table {
    display: column;
    justify-content: stretch;
    
    &__row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-top: .5rem;
        padding-bottom: .45rem;

        &__col {
            flex-grow: 0;
            flex-shrink: 0;
            padding-right: .5rem;

            &--10 {
                flex-basis: 10%;
            }
            &--15 {
                flex-basis: 15%;
            }
            &--25 {
                flex-basis: 25%;
            }
            &--33 {
                flex-basis: 33.33%;
            }
            &--50 {
                flex-basis: 50%;
            }
            &--65 {
                flex-basis: 65%;
            }
            &--75 {
                flex-basis: 75%;
            }
            &--80 {
                flex-basis: 80%;
            }
            &--90 {
                flex-basis: 90%;
            }
            &--100 {
                flex-basis: 100%;
            }
        }

        & + .stats-table__row {
          border-top: 1px solid darken($color-gray, 10%);
        }
    }
}