.mobile-bar {
    &__toggle {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        z-index: 100;

        height: 3rem;
        width: 3rem;
        border-radius: $border-radius;
        padding: 0;
        margin: 0;
        appearance: none;
        border: none;
        outline: none;
        box-shadow: $box-shadow;
        background-color: $color-red;
        transition: transform .12s ease-in;
        -webkit-tap-highlight-color: transparent;

        > img {
            height: 3rem;
            width: 3rem;
            object-fit: contain;
            object-position: center;
            padding: .75rem;
            transition: transform .12s ease-in;

            &.mobile-bar__toggle__open {
                display: block;
            }
            &.mobile-bar__toggle__close {
                display: none;
            }
        }

        &:hover {
            cursor: pointer;
            transform: scale(1.05);

            > img {
                transform: scale(1.25);
            }
        }
    }
}