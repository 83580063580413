.link-cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: flex-start;

    &__item {
        @include background-fill;
        width: 100%;
        max-width: 600px;
        box-shadow: $box-shadow;
        text-decoration: none;
        display: block;
        color: inherit;
        width: 100%;
        margin-bottom: $gap-size;
        transition: transform .05s ease-in;
        margin-right: $gap-size;

        display: flex;
        flex-direction: column;
        justify-content: stretch;
        
        &__title {
            text-align: center;
        }
        
        &__button {
            margin-top: auto;

            .cpx-button {
                width: 100%;
            }
        }

        @media screen and (min-width: $breakpoint-xl) {
            width: calc(50% - #{$gap-size * 0.5});
        }

        @media screen and (min-width: $breakpoint-xl) and (max-width: 2600px) {
            &:nth-child(even) {
                margin-right: 0;
            }
        }

        @media screen and (min-width: 2600px) {
            &:nth-child(3n) {
                margin-right: 0;
            }
        }

        &:hover {
            transform: scale(1.02);
        }
    }
}