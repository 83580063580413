.coming-soon-notice {
    @include background-fill;
    z-index: 205;
    width: 800px;
    max-width: calc(100% - 2rem);
    box-shadow: $box-shadow;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(1);
    top: 4rem;
    transition: opacity .2s ease-in-out, transform .2s ease-in-out;

    &__backdrop {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 200;
        background: radial-gradient(transparentize($color-red, .5), transparentize($color-dark, 0));
        transition: opacity .4s ease-in-out;
        backdrop-filter: blur(2px);
        opacity: 1;
    }  

    &.fadeout {
        transform: translateX(-50%) scale(.7);
        opacity: 0;
    }
}

.fadeout.coming-soon-notice__backdrop {
    opacity: 0;
}
