.context-info {
    width: 100%;
    max-width: calc(100% - $hud-max-width); // Prevent hud from overflowing
    padding-right: $gap-size;

    @media screen and (min-width: $breakpoint-xl) {
        margin-top: -140px;
    }

    &__button {
        position: relative;
        z-index: 3;

        .cpx-button {
            color: $color-text;
            background: rgba(255,255,255,.4);
            font-weight: normal;
            border: 1px solid $color-dark;
            font-size: .9em;

            padding: .25rem .65rem;
            transition: background-color .12s ease-in;


            &:hover {
                color: $color-text !important;
                background: rgba(255,255,255,.6);
            }
        }
    }

    &__page-title {
        position: relative;
        z-index: 2;

        h2 {
            margin-bottom: 0;
            text-shadow: $text-shadow-white;
        }
    }
}