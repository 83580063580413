.hud {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &__card {
        @include background-fill;
        width: $hud-max-width;
        max-width: 100%;
        box-shadow: $box-shadow;
    }

    &__profile-picture {
        width: 140px;
        height: 140px;

        .profile-picture img {
            width: calc(140px - 2rem);
            height: calc(140px - 2rem);
            border-radius: $border-radius;
            box-shadow: $box-shadow;
        }
    }

    &__stats {
        width: calc(100% - 140px);

        &__bar {
            width: 100%;
            height: 1.75rem;
            background-color: #8D8D8D;
            background: radial-gradient(#F4F4F4,#8D8D8D);
            border-radius: $border-radius;
            position: relative;
            overflow: hidden;

            &__label, &__value {
                font-family: $font-stack-monospace; 
                color: #fff;
                text-shadow: $text-shadow;
                z-index: 2;
            }
            &__label {
                position: absolute;
                left: .5rem;
                top: 50%;
                transform: translateY(-50%); 
            }
            &__value {
                position: absolute;
                right: .5rem;
                top: 50%;
                transform: translateY(-50%);
            }

            &__progress {
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 100%;
                background-color: #8D8D8D;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 50%;
                    width: 100%;
                    background-color: #fff;
                    opacity: .3;
                    filter: blur(5px);
                    border-radius: 100%;
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background-size: cover;
                    background-position: right;
                    background-repeat: no-repeat;
                    opacity: .19;
                }
            }

            &--hp .hud__stats__bar__progress {
                background: radial-gradient(#771028,#5A0318); 

                &:after {
                    background-image: url('../img/textures/blood.jpg');
                }
            }
            &--xp .hud__stats__bar__progress {
                background: radial-gradient(#21477B,#03285A); 

                &:after {
                    background-image: url('../img/textures/mana.jpg');
                }
            }
        }

        &__currency {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: .65rem;

            &__icon {
                background-color: $color-dark;
                border-radius: $border-radius;
                width: 1.75rem;
                height: 1.75rem;
                margin-right: .5rem;

                > img {
                    padding: .3rem;
                    object-fit: contain;
                    object-position: center;
                    height: 100%;
                    width: 100%;
                }
            }

            @media screen and (min-width: $breakpoint-xl) {
                display: inline-flex;
                & + .hud__stats__currency {
                    margin-left: 1.75rem;
                }
            }
        }
    }
} 