.sidebar {
    @include background-fill;
    position: relative;
    width: 100%;
    max-width: 100%;
    box-shadow: $box-shadow;
    z-index: 20;

    @media screen and (max-width: $breakpoint-xl) {
        padding-bottom: 5rem;
    }

    &__top {
        &__buttons {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            > a {
                height: 3rem;
                width: 3rem;
                background-color: transparentize(darken($color-gray, 10%), .5);
                transition: background-color .12s ease-in;
                
                > img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    object-position: center;
                    padding: .65rem;
                }

                &:not(:first-child) {
                    margin-left: .75rem;
                }

                &:hover {
                    background-color: darken($color-gray, 10%);
                }
            }
        }
    }

    &__menu {
        display: flex;
        flex-direction: column;

        &__item {
            width: 100%;
            position: relative;

            // All links
            a {
                display: block;
                color: inherit;
                text-decoration: none;
                white-space: nowrap;
            }

            // Level 1 links
            > a {
                padding-top: 1rem;
                padding-bottom: 1rem;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                font-weight: bold;
                font-size: 1.25rem;
                text-align: right;

                &:hover {
                    background-color: transparentize(darken($color-gray, 10%), .5);
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid darken($color-gray, 10%);
            }

            &__sub {
                width: auto;
                opacity: 0;
                pointer-events: none;
                user-select: none;
                position: absolute;
                right: 100%;
                top: 0;
                background-color: $color-gray;
                box-shadow: $box-shadow;
                border-radius: $border-radius;
                border-top-right-radius: 0;
                padding-top: .5rem;
                padding-bottom: .5rem;
                transition: opacity .08s ease-in, transform .08s ease-in;
                transform: translateX(4px);

                > a {
                    padding-top: .45rem;
                    padding-bottom: .45rem;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;

                    &:hover {
                        background-color: transparentize(darken($color-gray, 10%), .5);
                    }
                }
            }

            &__arrow {
                position: absolute;
                left: 1rem;
                top: 50%;
                transform: translateY(-50%);
                transition: transform .08s ease-in;
                height: 1.25rem;
            }

            &:hover, &:focus {
                .sidebar__menu__item__sub {
                    opacity: 1;
                    pointer-events: auto;
                    transform: translateX(0px);
                }
                .sidebar__menu__item__arrow {
                    transform: translateY(-50%) rotate(-90deg);
                }
            }
        }
    }

    &__mini-menu {
        display: flex;
        flex-direction: column;

        a {
            display: block;
            color: inherit;
            text-decoration: none;
            white-space: nowrap;
            text-align: right;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-top: .4rem;
            padding-bottom: .4rem;

            &:hover {
                background-color: transparentize(darken($color-gray, 10%), .5);
            }
        }
    }
}