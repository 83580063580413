.screen {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: $gap-size;

    max-width: 3000px;
    margin-left: auto;
    margin-right: auto;
    
    &__main {
        width: 100%;
        padding-left: #{$gap-size * 0.5};
        padding-right: #{$gap-size * 0.5};

        @media screen and (min-width: $breakpoint-xl) {
            width: calc(100% - #{$sidebar-width}); 
            padding-left: $gap-size;
            padding-right: $gap-size;
        }
    }
    &__view {
        padding-top: $gap-size;
    }
    &__sidebar {
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 50;
        overflow-y: auto;

        opacity: 0;
        pointer-events: none;
        user-select: none;
        transition: transform .15s ease-in-out;
        transform: scale(1.05);

        @media screen and (min-width: $breakpoint-xl) {
            z-index: auto;
            width: $sidebar-width;
            position: relative;
            left: auto;
            top: auto;
            height: auto;
            overflow: visible;

            opacity: 1;
            pointer-events: auto;
            user-select: auto;
            transform: scale(1);
        }
    }
}

.screen.mobile-menu-open {
    .screen__sidebar  {
        opacity: 1;
        transform: scale(1);
        pointer-events: all;
        user-select: auto;
    }
    
    .mobile-bar__toggle {
        .mobile-bar__toggle__open {
            display: none;
        }
        .mobile-bar__toggle__close {
            display: block;
        }
    }
}