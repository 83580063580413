.context-sidebar {
    @include background-fill;
    width: 100%;
    max-width: 100%;
    box-shadow: $box-shadow;

    @media screen and (min-width: $breakpoint-xl) {
        margin-top: -140px;
    }

    &__user-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid transparentize($color-gray, .8);

        &__badge {
            border-radius: $border-radius;
            height: 3rem;
            width: 3rem;
            background: radial-gradient(#5D5D5D, #242424);
            user-select: none;
            flex-shrink: 0;

            .profile-badge {
                width: 100%;
                height: 100%;
            }

            .profile-badge img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
                padding: .25rem;
            }
        }

        &__name {
            width: calc(100% - 3rem);
            // Display name
            > div:first-child {
                font-size: 1.25rem;
                font-weight: bold;
                overflow-wrap: break-word;
            }
        }
    }

    &__user-stats {
        display: flex;
        flex-direction: column;

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;

            > img {
                margin-right: 1rem;
                height: 1.5rem;
                width: 1.5rem;
                object-fit: contain;
                object-position: center;
            }

            & + .context-sidebar__user-stats__item {
                margin-top: 1rem;
            }
        }
    }
}