.stat-cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: flex-start;

    &__item {
        @include background-fill;
        width: 100%;
        max-width: 600px;
        box-shadow: $box-shadow;
        text-decoration: none;
        color: inherit;
        width: 100%;
        margin-bottom: $gap-size;
        margin-right: $gap-size;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &__icon {
            width: 100%;
            text-align: center;
            margin-bottom: 1em;
            
            img {
                width: 100px;
                height: auto;
            }
        }

        &__content {
            width: 100%;
        }
        
        &__title {

        }
        
        &__button {
            margin-top: auto;

            .cpx-button {
                width: 100%;
            }
        }

        @media screen and (min-width: $breakpoint-lg) {
            flex-direction: row;

            .stat-cards__item__icon {
                width: 150px;
                margin-bottom: 0;

                img {
                    width: 100%;
                }
            }

            .stat-cards__item__content {
                width: calc(100% - 150px);
            }
        }

        @media screen and (min-width: $breakpoint-xl) {
            width: calc(50% - #{$gap-size * 0.5});
        }

        @media screen and (min-width: $breakpoint-xl) and (max-width: 2600px) {
            &:nth-child(even) {
                margin-right: 0;
            }
        }

        @media screen and (min-width: 2600px) {
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
}