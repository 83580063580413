.profile-picture {
    position: relative;

    > img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
    }

    &__rank {
        position: absolute;
        font-size: 1rem;
        top: .5rem;
        right: .5rem;
        font-family: $font-stack-monospace;
        text-shadow: $text-shadow;
        color: #fff;
    }
}